import styled from 'styled-components';
import { getColor, getSpacing, getTypographyColor, getTypographySize, getTypographyWeight } from '../../theme/helpers';
import { MEDIA } from '../../constants';

export const SectionWrapper = styled.div`
  padding-block: ${getSpacing('lg')}`
;

export const Header = styled.h1`
  font-size: ${getTypographySize('lg')};
  padding-bottom: ${getSpacing('lg')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xxl')};
  }
`;

export const TextWrapper = styled.div`
  width: 100%;

  @media (min-width: ${MEDIA.MED}) {
    width: 75%;
    margin: 0 auto;
  }
`;

export const Paragraph = styled.p`
  color: ${getTypographyColor('brandBlue')};
  font-size: ${getTypographySize('small')};
  line-height: 21px;
  margin-bottom: ${getSpacing('sm')};
  font-weight: ${getTypographyWeight('normal')};

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('basic')};
    letter-spacing: 0.0125rem;
  }
`;

export const ParagraphCentered = styled(Paragraph)`
  text-align: center;
  margin-bottom: 0;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-bottom: ${getSpacing('sm')};
`;

export const ListElement = styled.li`
  color: ${getTypographyColor('brandBlue')};
  font-size: ${getTypographySize('small')};
  line-height: 21px;
  letter-spacing: 0.0125rem;
  margin-left: ${getSpacing('xl')};

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('basic')};
  }
`;

export const Subheader = styled.h2`
  font-size: ${getTypographySize('xmd')};
  padding-block: ${getSpacing('sm')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xl')};
  }
`;

export const SubSubheader = styled.h3`
  font-size: ${getTypographySize('basic')};
  padding-block: ${getSpacing('sm')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xmd')};
  }
`;

export const FormSectionWrapper = styled.div`
  background-color: ${getColor('backgroundGray')};
  padding-block: ${getSpacing('lg')};
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: ${MEDIA.MAX}) {
    max-width: 75%;
  }
`;

export const EbookTextWrapper = styled.div`
  max-width: 75%;
`;

export const EbookTextHeader = styled.p`
  font-size: ${getTypographySize('md')};
  padding-bottom: ${getSpacing('sm')};
  color: ${getTypographyColor('brandGreen')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xl')};
  }
`;

export const EbookText = styled(EbookTextHeader)`
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('normal')};
`;

export const EbookTextSpan = styled.span`
  display: block;
`;

export const EbookTextSpanBold = styled(EbookTextSpan)`
  font-weight: bold;
`;

export const TabletImage = styled.img`
  align-self: center;
  margin-bottom: ${getSpacing('md')};
  max-width: 256px;

  @media (min-width: ${MEDIA.MED}) {
    max-width: 50%;
  }
`;

export const BioSectionWrapper = styled.div`
  background-color: ${getColor('brandBlue')};
  padding-top: ${getSpacing('xl')};
  padding-bottom: ${getSpacing('xxl')};
  display: flex;
  flex-wrap: wrap;
`;

export const BioTextWrapper = styled.div`
  color:  ${getColor('basicWhite')};
  font-size: ${getTypographySize('small')};

  @media (min-width: ${MEDIA.MED}) {
    max-width: 50%;
    font-size: ${getTypographySize('basic')};
  }
`;

export const BioSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: ${MEDIA.MED}) {
    justify-content: space-between;
  }
`;

export const BioTextName = styled.span`
  font-size: ${getTypographySize('lg')};
  display: block;
  margin-bottom: ${getSpacing('lg')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xl')};
  }
`;

export const BioTextGreeting = styled(BioTextName)`
  font-size: ${getTypographySize('md')};
  font-weight: ${getTypographyWeight('normal')};
  margin-top: ${getSpacing('md')};

  @media (min-width: ${MEDIA.MED}) {
    margin-top: 0;
  }
`;

export const BioImage = styled.img`
  width: 540px;
  max-width: 100%;

  @media (min-width: ${MEDIA.MED}) {
    max-width: 45%;
  }
`;
