import React from 'react';
import { Container } from '../Container';
import { ToggleList } from '../ToggleList/ToggleList';
import * as Styled from './CourseInfo.styles';
import tabletImg from '../../../static/4-narzedzia-do-ddd-cqrs-es-na-platformie-java-pdf-okladka.png';
import trainerImg from '../../../static/piotr-wyczesany.webp'

export const CourseInfo = () => (
  <main>
    <Container>
      <Styled.SectionWrapper>
        <Styled.Header>
          Kurs DDD/CQRS/ES Applied on JVM with Axon Framework
        </Styled.Header>
        <Styled.TextWrapper>
          <Styled.Paragraph>Dowiedz się jak skutecznie wprowadzić:</Styled.Paragraph>
          <Styled.List>
            <Styled.ListElement>Domain-Driven Design, </Styled.ListElement>
            <Styled.ListElement>Command Query Responsibility Segragation,</Styled.ListElement>
            <Styled.ListElement>Event Sourcing</Styled.ListElement>
          </Styled.List>
          <Styled.Paragraph>
            do Twojego systemu opartego o platformę JVM - zarówno legacy, jak i greenfield - przy użyciu Axon Framework.
          </Styled.Paragraph>
          </Styled.TextWrapper>
        <Styled.Subheader>Kurs startuje już niebawem, a tymczasem...</Styled.Subheader>
      </Styled.SectionWrapper>
    </Container>

    <Styled.FormSectionWrapper>
      <Container>
        <Styled.FormWrapper>
          <Styled.TabletImage alt="tablet" src={tabletImg} />
          <Styled.EbookTextWrapper>
            <Styled.EbookTextHeader>Pobierz darmowy ebook:</Styled.EbookTextHeader>
            <Styled.EbookText>
              <Styled.EbookTextSpan>4 narzędzia do</Styled.EbookTextSpan>
              <Styled.EbookTextSpanBold>DDD/CQRS/ES</Styled.EbookTextSpanBold>
              <Styled.EbookTextSpan>na platformie Java</Styled.EbookTextSpan>
            </Styled.EbookText>
            <Styled.ParagraphCentered>* Plus jedno, błędnie za takie postrzegane - BONUS!</Styled.ParagraphCentered>
            <div className="ml-embedded" data-form="FUJi56"></div>
          </Styled.EbookTextWrapper>
        </Styled.FormWrapper>
      </Container>
    </Styled.FormSectionWrapper>

    <Container>
      <Styled.Subheader>Co znajdziesz w kursie - 16 tygodni:</Styled.Subheader>

      <Styled.SubSubheader>Część I - Refactoring sposobu myślenia - 6 tygodni</Styled.SubSubheader>
      <ToggleList header="Moduł 0: Plan gry." listElements={[
        'Jaki efekt osiągnę po każdym etapie?',
        'Struktura i budowa kursu.',
        'Co przygotować?',
        'Czyjej zgody potrzebuję?',
        'Inne obawy związane z DDD/CQRS/ES.',
      ]} />
      <ToggleList header="Moduł 1: System zastany i pierwszy refactoring w kierunku DDD." listElements={[
        'Jakbyś zaczęła/zaczął nowy system teraz?',
        'Złożoność przypadkowa a esencjonalna.',
        'Krótka lekcja historii.',
        'Przegląd systemu zastanego.',
        'Pierwszy refactoring w kierunku DDD.',
      ]} />
      <ToggleList header="Moduł 2: CQRS tak po prostu." listElements={[
        'Command-Query Separation.',
        'Location transparency.',
        'Pierwsze starcie z systemami legacy.',
        'Jak wysyłać i przetwarzać Command i Query?',
        'Value Objects w API - tak, czy nie?',
      ]} />
      <ToggleList header="Moduł 3: Event Sourcing - część 1: Command Model." listElements={[
        'Event.',
        'Command vs Event.',
        'Stan jako pochodna Eventów.',
        'Infrastruktura do Event Sourcingu.',
        'Odrobina magii Axona.',
        'Świat bez JPA.',
      ]} />
      <ToggleList header="Moduł 4: Event Sourcing - część 2: Query Model." listElements={[
        'Po co nam drugi model?',
        'Komunikacja typu push vs pull.',
        'Jak Axon przetwarza Zdarzenia?',
        'Obsługa Zdarzeń po stronie Query.',
        'Obsługa błędów.',
      ]} />
      <ToggleList header="Moduł 5: Testowanie i chwila olśnienia." listElements={[
        'Event Sourcing z punktu widzenia testów.',
        'Given-When-Then ze Zdarzeniami.',
        'Wsparcie Axona dla testów GWT.',
        'Jak przetestować, że coś się NIE zmieniło?',
        'Co z tymi polami w Agregacie?',
        '"The picture that says it all".',
      ]} />
      <ToggleList header="Moduł 6: Gdzie (nie) używać CQRS." listElements={[
        'Gdzie i po co używać CQRS.',
        'Gdzie i czemu NIE używać CQRS.',
        'CQRS to nie top-level architektura.',
        'Kwadrant microservice’ów.',
        'Microservice vs Moduł z CQRSem.',
        'Kwadrant strategicznego DDD.',
      ]} />

      <Styled.SubSubheader>Część II - Event-Driven Architecture, Message-Driven Architecture i Struktury Wielkiej Skali - 6 tygodni</Styled.SubSubheader>

      <ToggleList header="Moduł 7: Event-Driven Architecture." listElements={[
        'Asynchroniczność.',
        'Infrastruktura Axona do asynchroniczności.',
        'Obsługa błędów.',
        'Czym EDA się różni od ES?',
        'Subscription Query w czasie rzeczywistym.',
      ]} />
      <ToggleList header="Moduł 8: Message-Driven Architecture." listElements={[
        'Trzy rodzaje message\'y.',
        'Anatomia message\'a.',
        'Dodatkowe parametry obsługi message\'a.',
        'Scatter-Gather Query.',
        'Streaming Query.',
      ]} />
      <ToggleList header="Moduł 9: Warstwa Aplikacyjna." listElements={[
        'Rozszerzony "the picture that says it all" o Politykę.',
        'Wzorzec Application Service.',
        '"Czy ten użytkownik już istnieje?" - czyli set-based validation.',
        'Wysoka współbieżność.',
        'Rozwiązywanie konfliktów.',
      ]} />
      <ToggleList header="Moduł 10: Saga i Struktury Wielkiej Skali." listElements={[
        'Dlaczego mnie okłamali z przelewem bankowym?',
        'Dwa modele transakcji: ACID vs BASE.',
        'Długo żyjące transakcje.',
        'Rozszerzony "the picture that says it all" o stan.',
        'Saga i jej infrastruktura w Axonie.',
      ]} />
      <ToggleList header="Moduł 11: Saga i Czas." listElements={[
        'Wymagania oparte o czas.',
        'Klasyczne sposoby.',
        'Rozszerzony "the picture that says it all" o zegar.',
        'Zdarzenia zachodzące w czasie.',
        'Deadline’y.',
        'Infrastruktura schedulerów.',
      ]} />
      <ToggleList header="Moduł 12: Testowanie." listElements={[
        '3 rodzaje testów message\'ami.',
        'Testowanie Sag.',
        'Testowanie czasu.',
        'Testowanie warstwy aplikacyjnej.',
      ]} />

      <Styled.SubSubheader>Część III - Życie na produkcji, czyli rzeczy, o których nikt nie mówi, bo ich nie przeżył i BONUSY - 4 tygodnie</Styled.SubSubheader>
      <ToggleList header="BONUS 1 - Długo Żyjące Systemy 1/2." listElements={[
        'Strategie na budowanie małych agregatów.',
        'Struktura Eventów i jej ewolucja.',
        'Zmiany są nieuniknione - upcasting.',
        'Problem: za długi czas ładowania Agregatu - snapshotting.',
        'Problem: za długi czas ładowania Agregatu - cache.',
        'Problem: za długi czas ładowania Sagi - cache.',
      ]} />
      <ToggleList header="BONUS 1 - Długo Żyjące Systemy 2/2." listElements={[
        'Tuning bazy danych.',
        '"DROP * FROM QueryModel" - replay.',
        '"DROP * FROM QueryModel" - zero-downtime replay.',
        'Historia jest (prze)pisana przez zwycięzców.',
        'Czasem nie potrzeba całej historii - wzorzec "closing the books".',
        'Axon Server jako baza i nie tylko.',
      ]} />
      <ToggleList header="BONUS 2: Wprowadzenie ES do Istniejącego Systemu." listElements={[
        'Kwadrant "Migration Decision Canvas".',
        'Próba ekstrakcji historii z systemu.',
        'Sprecyzowane Zdarzenie: DataImportedEvent.',
        'Strategiczny wzorzec: Anti-Corruption Layer.',
        'Saga jako Anti-Corruption Layer.',
        'Kto jest kim - Producer of Message vs Producer of API.',
      ]} />
      <ToggleList header="BONUS 3: Spokojny sen." listElements={[
        'Metryki.',
        'Zdrowie systemu.',
        'Status przetwarzania Zdarzeń.',
        'Śledzenie wiadomości.',
      ]} />
    </Container>

    <Styled.SectionWrapper>
      <Styled.Subheader>Kto prowadzi?</Styled.Subheader>
    </Styled.SectionWrapper>
    <Styled.BioSectionWrapper>
      <Container>
        <Styled.BioSection>
          <Styled.BioImage alt="Piotr Wyczesany" src={trainerImg} />
          <Styled.BioTextWrapper>
            <Styled.BioTextGreeting>
              Cześć, jestem
            </Styled.BioTextGreeting>
            <Styled.BioTextName>Piotr Wyczesany</Styled.BioTextName>
            <p>Pomagam zespołom programistycznym wypracować bardziej strategiczne podejście,
              koncentrując ich działania na głębokim zrozumieniu domeny biznesowej, w której pracują,
              zamiast skupiać się głównie na rozwiązaniach technicznych.</p>
            <br />
            <p>Pierwszy system oparty na założeniach DDD/CQRS/ES wypuściłem produkcyjnie już w 2012 roku, a następny (znacznie większy) w 2016.</p>
            <br />
            <p>W czasie tej ponad dekady przeprowadziłem wiele szkoleń, nauczyłem tematu dziesiątki ludzi i teraz chcę tę wiedzę przekazać w przystępnym formacie jeszcze większemu gronu odbiorców.</p>
          </Styled.BioTextWrapper>
          </Styled.BioSection>
      </Container>
    </Styled.BioSectionWrapper>
  </main>
)