import React, { useState } from 'react';
import * as Styled from './ToggleList.styled';

import checkmark from '../../../static/check-mark.svg';
import arrowDown from '../../../static/arrow-down.svg';
import arrowUp from '../../../static/arrow-up.svg';

export const ToggleList = ({ header, listElements }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styled.Wrapper>
      <Styled.ToggleHeader onClick={() => setIsOpen(!isOpen)}>
        {header}
        <span><Styled.Arrow src={isOpen ? arrowUp : arrowDown} /></span>
      </Styled.ToggleHeader>
      {isOpen && (
        <Styled.List isOpen={isOpen}>
          {listElements.map(element => (
            <Styled.ListElement> <Styled.CheckMark src={checkmark} alt={'checkmark'}/> {element}</Styled.ListElement>
          ))}
        </Styled.List>
      )}
    </Styled.Wrapper>
  )
}