import React from 'react';
import {Helmet} from 'react-helmet';
import {string} from 'prop-types';

import Layout from '../components/Layout';

import {HOME_PAGE_TITLE} from '../constants';
import { CourseInfo } from '../components/CourseInfo/CourseInfo';


const Home = ({path}) => {

  return (
    <Layout path={path}>
      <Helmet>
        <title>
          {HOME_PAGE_TITLE}
        </title>
        <meta name='keywords' content='ddd,cqrs,event sourcing,axon,kurs,domain driven design'/>
        <meta name='description' content='Kurs DDD/CQRS/ES Applied on JVM with Axon Framework'/>
        {/*MailerLite Universal*/}
        <script type="text/javascript">{`
          (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
          .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
          n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
          (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
          ml('account', '611544');
        `}</script>
        {/*MailerLite Universal*/}
      </Helmet>
      <CourseInfo />
    </Layout>
  )
}

Home.propTypes = {
  path: string.isRequired,
}

export default Home;
