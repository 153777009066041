import styled, { css } from 'styled-components';
import { getColor, getSpacing } from "../../theme/helpers";

export const Wrapper = styled.div`
  max-width: 650px;
  margin: 0 auto;
  margin-block: ${getSpacing('md')};
`;

export const ToggleHeader = styled.span`
  padding: ${getSpacing('md')} ${getSpacing('md')};
  border: 1px solid ${getColor('brandGreen')};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  color: ${getColor('darkGrey')};
  
  :hover {
    cursor: pointer ;  
    background-color: ${getColor('backgroundGray')};
  }
`;

export const ListElement = styled.li`
  padding:  ${getSpacing('sm')};
`;

export const CheckMark = styled.img`
  height: ${getSpacing('basic')};
  margin: 0 ${getSpacing('basic')};
`;

export const Arrow = styled.img`
  height: ${getSpacing('basic')};
  margin: 0 ${getSpacing('basic')};
`;

export const List = styled.ul`
  color: ${getColor('darkGrey')};
`;
